import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'hooks/useLanguage';
import { FaSearch } from 'react-icons/fa';
import { useFormikContext } from 'formik';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField, DatePickerInputField } from 'components/FormikFields';

import styles from 'assets/styles/invoice.scss';
import {
  Currencies,
  CurrenciesFullName,
  DOCUMENT_TYPE_OPTIONS,
  Invoice_Kind,
  TAX_RATE_OPTIONS,
} from '../../../../config/invoice/config';
import SelectField from '../../../../components/FormikFields/SelectField';

const InvoiceMainSection = ({ onOriginalInvoiceNumberChange, error }) => {
  const { t } = useTranslation();
  const { setFieldValue, values, handleChange, resetForm } = useFormikContext();
  const language = useLanguage();
  console.log('values', values);

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      iconClickHandler();
    }
  };
  const iconClickHandler = () => {
    onOriginalInvoiceNumberChange(values.originalInvoiceNumber, setFieldValue);
  };

  return (
    <div className={styles.section}>
      {error && <div>ERORRRRRRRRRR</div>}
      <Typography weight="bold" className={styles.title}>
        {t('newInvoice_invoiceData')}
      </Typography>
      <div className={language === 'ar' ? 'row flex-row-reverse' : 'row'}>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="buyerInvoiceNumber"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('newInvoice_vendor_invoiceNumber')}
          />
        </div>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="noteType"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_notice_type')}
          />
        </div>
        <div class="col-md-4 col-sm-12">
          <FormikField
            name="issueDate"
            component={DatePickerInputField}
            componentProps={{
              // fieldClassName: styles.groupField,
              disabledDays: [{ after: new Date() }],
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_invoice_issueDate')}
          />
        </div>
        <div
          className={
            language === 'ar' ? 'col-md-5 col-sm-12' : 'col-md-6 col-sm-12'
          }
        >
          <FormikField
            name="originalInvoiceNumber"
            component={InputField}
            componentProps={{
              iconElement: <FaSearch className="searchIcon" />,
              iconClickHandler,
              onKeyDown: handleSearchKeyPress,
              onChange: (e) => {
                resetForm();
                handleChange(e);
              },
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_invoice_reference')}
          />
        </div>
        <div
          className={
            language === 'ar' ? 'col-md-3 col-sm-12' : 'col-md-6 col-sm-12'
          }
        >
          <FormikField
            name="originalInvoiceTotal"
            component={InputField}
            componentProps={{
              disabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_invoice_total_Amount')}
          />
        </div>
        <div
          className={
            language === 'ar' ? 'col-md-4 col-sm-12' : 'col-md-6 col-sm-12'
          }
        >
          <FormikField
            name="reasonOfNote"
            component={InputField}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('newInvoice_reason_issuing_notice')}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormikField
            name="currencyType"
            component={SelectField}
            componentProps={{
              options: Currencies(t).map((item) => ({
                value: item.value,
                // label: `${item.label}`,
                label: `${CurrenciesFullName(t)[item.value]} (${item.label})`,
              })),
              isDisabled: true,
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            required
            label={t('Currency_Type')}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <FormikField
            name="invoiceKind"
            component={SelectField}
            componentProps={{
              options: Invoice_Kind(t),
              // defaultValue: Invoice_Kind(t)[0],
              isDisabled: true,
              onChange: (data) => {
                if (data.value !== 'LOCAL') {
                  setFieldValue('tempItem.specialTaxAmount', 0);
                  setFieldValue(
                    'tempItem.generalTaxPercentage',
                    TAX_RATE_OPTIONS(t).find((item) => item.value === 'ZERO'),
                  );
                }
                if (data.value === 'DEVELOPMENTAL') {
                  setFieldValue(
                    'buyer.additionalBuyerIdType',
                    DOCUMENT_TYPE_OPTIONS(t).find(
                      (item) => item.value === 'TAXPAYER_NUMBERS',
                    ),
                  );
                } else {
                  setFieldValue('buyer.additionalBuyerIdType', '');
                }
              },
            }}
            controlProps={{
              labelProps: {
                className: styles.fieldLabel,
              },
            }}
            label={t('Invoice_Kind')}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(InvoiceMainSection);
