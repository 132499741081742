export const contactInfo = [
  {
    en: 'Mohammad Al-Rashdan',
    ar: 'محمد الرشدان',
    value: '0772462320',
    type: 'tel:',
  },
  {
    en: 'Fadi Alhamad',
    ar: 'فادي الحمد',
    value: '0790122303',
    type: 'tel:',
  },
  {
    en: 'Moneer Al-Zboun',
    ar: 'منير الزبون',
    value: '0772244584',
    type: 'tel:',
  },
  {
    en: 'Belal Al-Hendi',
    ar: 'بلال الهندي',
    value: '0799069084',
    type: 'tel:',
  },
  {
    en: 'Omran Al-Azzazi',
    ar: 'عمران العزازي',
    value: '0795871614',
    type: 'tel:',
  },
  {
    en: 'Mohammad Al-kilani',
    ar: 'محمد الكيلاني',
    value: ' 0776180808',
    type: 'tel:',
  },
  {
    en: 'Ashraf Mobaideen',
    ar: 'اشرف مبيضين',
    value: '0798943280',
    type: 'tel:',
  },
  {
    manager: true,
    en: 'Ayman Alkhawaldeh',
    ar: 'ايمن الخوالدة',
    value: '0799055201',
    type: 'tel:',
  },
];
