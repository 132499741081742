import i18n from 'config/i18n';

export const defaultItemData = (
  generalTaxPercentage = { label: '16%', value: 'SIXTEEN' },
) => {
  const language = i18n.language;
  return {
    creating: true,
    totalAmountAfterTaxes: '',
    generalTaxAmount: '',
    generalTaxPercentage: generalTaxPercentage,
    totalAmountAfterDiscount: '',
    discountAmount: 0,
    subtotalAmount: '',
    specialTaxAmount: 0,
    unitPrice: '',
    customerPrice: '',
    quantity: '',
    description: '',
    isic4: '',
    type: {
      value: 'PRODUCT',
      label: language === 'en' ? 'good/service' : 'سلعة/خدمة',
    },
  };
};

export const PHONE_NUMBER_REGEX = /^[+]?\d{9,14}$/;

export const POSTAL_CODE_REGEX = /^\d{0,5}$/;

export const NAME_REGEX = /^[a-zA-Zء-ي0-9\s]+$/;
