import axios from 'axios';
import { v4 as uuid } from 'uuid';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import { TOKEN_KEY, OTP_KEY, SESSION_TIMEOUT, API_URL } from 'constants/common';

import i18n from 'config/i18n';

import { getBuyerIdTypeTextByCode } from 'helpers/getBuyerIdTypeTextByCode';

export const getTaxNumber = (token) =>
  axios.get(API_URL + '/on-boarding/get-tax-number', {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

export const getNextInvoiceNumber = () =>
  axios.get(API_URL + '/sme/invoices/next-tax-number');

export const getUserInfo = () =>
  axios.get('/users/').then((response) => {
    const language = i18n.language;

    return {
      activitiesList: response.activitiesList || null,
      activityNumber: response.activitiesList[0]?.activity || '-',
      country: response.countryDTO
        ? {
            code: response.countryDTO?.countryCode,
            name:
              language === 'en'
                ? response.countryDTO?.countryNameEn
                : response.countryDTO?.countryNameAr,
          }
        : '-',
      username: response.userName || '-',
      name: response.name || '-',
      invoiceTypeNumber: response.activitiesList[0]?.invoiceType, // 0 - INCOME_TAX 1 - GENERAL_TAX 2 - SPECIAL_TAX
      // invoiceTypeNumber: '2',
      role: response.role,
      phoneNumber: response.phoneNumber || '-',
      postalCode: response.postalCode || '-',
      taxNumber: response.taxNumber || '-',
      deviceInUse: response.deviceInUse || false,
    };
  });

export const registerUser = ({ token, data }) =>
  axios
    .post(API_URL + '/on-boarding/register-taxpayer', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => ({
      token: response.access_token,
      role: response.role,
    }));

export const loginUser = (data) =>
  axios.post(API_URL + '/users/auth/login', data).then((response) => {
    sessionStorage.setItem(
      SESSION_TIMEOUT,
      new Date().getTime() + response.expires_in,
    );
    return {
      token: response.access_token,
      refreshToken: response.refresh_token,
      role: response.role,
    };
  });

export const getAllDevices = () =>
  axios.get('/users/devices/').then((devices) =>
    devices.map((device) => ({
      id: device.id,
      clientId: device.clientId,
      secretKey: device.secretKey,
      name: device.deviceName,
      enabled: device.enabled,
      activityNumber: device.activityDTO?.activity,
    })),
  );

export const addDevice = (data) =>
  axios.post(API_URL + '/users/devices/', data);

export const getUserInvoice = (invoiceNumber, activity) => {
  const config = {
    headers: {
      activity: activity,
    },
  };

  return axios.get(API_URL + `/sme/invoices/get-uuid/${invoiceNumber}`, config);
};

export const checkIfTheUserHaveInvoices = (activity) =>
  axios.get(API_URL + '/sme/invoices/check-invoices', {
    headers: { activity: activity },
  });

export const disableDeviceById = (deviceId) =>
  axios.put(`${API_URL}/users/devices/${deviceId}/status`, { enabled: false });

export const enableDeviceById = (deviceId) =>
  axios.put(`${API_URL}/users/devices/${deviceId}/status`, { enabled: true });

export const getAllSubAdmins = () => {
  const otp = localStorage.getItem(OTP_KEY);

  return axios
    .get(API_URL + '/users/sub-admins/', {
      headers: {
        OTP: otp,
      },
    })
    .then((subAdmins) =>
      subAdmins.map((subAdmin) => ({
        id: subAdmin.id,
        username: subAdmin.username,
        password: '',
        notes: subAdmin.notes,
        enabled: subAdmin.enabled,
        activityNumber: subAdmin.activityDTO,
      })),
    );
};

export const addSubAdmin = (data) => {
  const otp = localStorage.getItem(OTP_KEY);

  return axios.post(API_URL + '/users/sub-admins/', data, {
    headers: {
      OTP: otp,
    },
  });
};

export const updateSubAdmin = (adminId, data) => {
  const otp = localStorage.getItem(OTP_KEY);

  return axios.put(`${API_URL}/users/sub-admins/${adminId}`, data, {
    headers: {
      OTP: otp,
    },
  });
};

export const disableSubAdminById = (adminId) => {
  const otp = localStorage.getItem(OTP_KEY);

  return axios.put(
    `${API_URL}/users/sub-admins/${adminId}/status`,
    { enabled: false },
    {
      headers: {
        OTP: otp,
      },
    },
  );
};

export const enableSubAdminById = (adminId) => {
  const otp = localStorage.getItem(OTP_KEY);

  return axios.put(
    `${API_URL}/users/sub-admins/${adminId}/status`,
    { enabled: true },
    {
      headers: {
        OTP: otp,
      },
    },
  );
};

export const generateOTP = () => axios.post(API_URL + '/users/otp/generate');

export const verifyOTP = (data) =>
  axios.post(API_URL + '/users/otp/verify', data);

export const getAllActivities = () =>
  axios.get(API_URL + '/users/user/activities/');

export const getInvoiceType = (activity) =>
  axios.get(API_URL + '/sme/invoices/check-invoices-type', {
    headers: { activity: activity },
  });

export const getAllInvoices = (params, activityNumber) => {
  const token = localStorage.getItem(TOKEN_KEY);

  return axios
    .get(API_URL + '/sme/invoices/', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        activity: activityNumber,
      },
      params: {
        ...params,
      },
    })
    .then((data) => ({
      totalOfTotals: data.totalInvoicePayableAmount,
      generatedThisYear: data.generatedThisYear,
      generatedInvoices: data.generatedInvoices,
      generatedNotes: data.generatedNotes,
      totalPages: data.totalPages,
      totalItems: data.totalItems,
      invoices: data.invoiceList.map((invoice) => ({
        id: invoice.invoiceUniqueIdentifier,
        invoiceType: invoice.invoiceTypeCode,
        invoiceStatus: invoice.invoiceStatusEnum,
        invoiceNumber: invoice.invoiceNumber,
        invoiceTotal: invoice.totalPayableAmount,
        issueDate: invoice.issueDate,
        noteType: invoice.noteType,
        activityNumber: invoice.activity,
        username: invoice.userName,
        currencyType: invoice.currencyEnum || 'JOD',
        invoiceKind: invoice.invoiceKind || 'LOCAL',
      })),
    }));
};

export const submitInvoice = (data) =>
  axios
    .post(API_URL + '/sme/invoices/', data, {
      headers: { 'upload-from': 'WEB' },
    })
    .then((response) => ({
      invoiceId: response.invoiceUniqueIdentifier,
      invoiceNumber: response.invoiceNumber,
    }));

export const cancelInvoice = ({ invoiceId, invoiceNumber }) =>
  axios.delete(`${API_URL}/sme/invoices/${invoiceId}/${invoiceNumber}`);

export const getOriginalInvoiceById = ({
  invoiceId,
  invoiceNumber,
  activity,
}) => {
  const language = i18n.language;

  return axios
    .get(
      `${API_URL}/sme/invoices/original-invoices/${invoiceId}/${invoiceNumber}`,
      { headers: { Activity: activity } },
    )
    .then((invoice) => ({
      id: invoice.invoiceUniqueIdentifier,
      invoiceType: invoice.invoiceTypeCode,
      invoiceStatus: invoice.invoiceStatus,
      invoiceNumber: invoice.invoiceNumber,
      invoiceKind: invoice.invoiceKind || 'LOCAL',
      currencyType: invoice.currencyEnum || 'JOD',
      buyerInvoiceNumber: invoice.buyerInvoiceNumber,
      originalInvoiceNumber: invoice.originalInvoiceNumber,
      originalInvoiceUUID: invoice.originalInvoiceUUID,
      noteType: invoice.noteType,
      reasonOfNote: invoice.reasonOfNote,
      activityDTO: invoice.activityDTO,
      issueDate: format(
        parse(invoice.issueDate, 'dd-MM-yyyy', new Date()),
        'dd/MM/yyyy',
      ),
      qrCodeImage: invoice.qrCodeImage,
      seller: {
        name: invoice?.sellerDTO?.name,
        isCustomerPriceEnabled: invoice?.sellerDTO?.isCustomerPriceEnabled,
        phoneNumber: invoice?.sellerDTO?.mobileNumber,
        postalCode: invoice?.sellerDTO?.postalCode,
        taxNumber: invoice?.sellerDTO?.taxNumber,
        activityNumber: invoice?.activityDTO?.activity,
        country:
          language === 'en'
            ? invoice?.sellerDTO?.countryDTO?.countryNameEn
            : invoice?.sellerDTO?.countryDTO?.countryNameAr,
      },
      buyer: {
        name: invoice?.buyerDTO?.buyerName,
        phoneNumber: invoice?.buyerDTO?.phoneNumber,
        postalCode: invoice?.buyerDTO?.postalCode,
        buyerId: invoice?.buyerDTO?.additionalBuyerId,
        buyerIdTypeCode: invoice?.buyerDTO?.additionalBuyerIdType,
        buyerIdType: getBuyerIdTypeTextByCode(
          invoice?.buyerDTO?.additionalBuyerIdType,
        ),
        provinceCode: invoice?.buyerDTO?.provinceDTO?.provinceCode,
        province:
          language === 'en'
            ? invoice?.buyerDTO?.provinceDTO?.provinceNameEn
            : invoice?.buyerDTO?.provinceDTO?.provinceNameAr,
      },
      items: invoice.invoiceItemDTOList
        ? invoice.invoiceItemDTOList.map((item) => ({
            id: item.uuid,
            type: item.invoiceItemType,
            totalAmountAfterTaxes: item.totalAmountAfterTaxes,
            generalTaxAmount: item.generalTaxAmount,
            generalTaxPercentage: item.generalTaxPercentage,
            generalTaxType: item.generalTaxType,
            totalAmountAfterDiscount: item.totalAmountAfterDiscount,
            discountAmount: item.discountAmount,
            subtotalAmount: item.subtotalAmount,
            specialTaxAmount: item.specialTaxAmount,
            unitPrice: item.unitPrice,
            customerPrice: item.customerPrice,
            quantity: item.quantity,
            description: item.productDescription,
          }))
        : [],
      totalAmountExcludingTaxes: invoice.totalAmountExcludingTaxes,
      totalDiscountsAmount: invoice.totalDiscountsAmount,
      totalGeneralTaxesAmount: invoice.totalGeneralTaxesAmount,
      totalPayableAmount: invoice.totalPayableAmount,
      notes: invoice.notes,
    }));
};

export const getInvoiceById = ({ invoiceId, invoiceNumber }) => {
  const language = i18n.language;
  return axios
    .get(`${API_URL}/sme/invoices/${invoiceId}/${invoiceNumber}`)
    .then((invoice) => ({
      id: invoice.invoiceUniqueIdentifier,
      invoiceType: invoice.invoiceTypeCode,
      invoiceStatus: invoice.invoiceStatus,
      invoiceNumber: invoice.invoiceNumber,
      invoiceKind: invoice.invoiceKind || 'LOCAL',
      currencyType: invoice.currencyEnum || 'JOD',
      buyerInvoiceNumber: invoice.buyerInvoiceNumber,
      originalInvoiceNumber: invoice.originalInvoiceNumber,
      noteType: invoice.noteType,
      invoiceTypeCode: invoice.invoiceTypeCode,
      reasonOfNote: invoice.reasonOfNote,
      activityDTO: invoice.activityDTO,
      issueDate: format(
        parse(invoice.issueDate, 'dd-MM-yyyy', new Date()),
        'dd/MM/yyyy',
      ),
      qrCodeImage: invoice.qrCodeImage,
      seller: {
        name: invoice?.sellerDTO?.name,
        phoneNumber: invoice?.sellerDTO?.mobileNumber,
        postalCode: invoice?.sellerDTO?.postalCode,
        taxNumber: invoice?.sellerDTO?.taxNumber,
        activityNumber: invoice?.activityDTO?.activity,
        country:
          language === 'en'
            ? invoice?.sellerDTO?.countryDTO?.countryNameEn
            : invoice?.sellerDTO?.countryDTO?.countryNameAr,
      },
      buyer: {
        name: invoice?.buyerDTO?.buyerName,
        phoneNumber: invoice?.buyerDTO?.phoneNumber,
        postalCode: invoice?.buyerDTO?.postalCode,
        buyerId: invoice?.buyerDTO?.additionalBuyerId,
        buyerIdTypeCode: invoice?.buyerDTO?.additionalBuyerIdType,
        buyerIdType: getBuyerIdTypeTextByCode(
          invoice?.buyerDTO?.additionalBuyerIdType,
        ),
        provinceCode: invoice?.buyerDTO?.provinceDTO?.provinceCode,
        province:
          language === 'en'
            ? invoice?.buyerDTO?.provinceDTO?.provinceNameEn
            : invoice?.buyerDTO?.provinceDTO?.provinceNameAr,
      },
      items: invoice.invoiceItemDTOList
        ? invoice.invoiceItemDTOList.map((item) => ({
            id: uuid(),
            type: item.invoiceItemType,
            totalAmountAfterTaxes: item.totalAmountAfterTaxes,
            generalTaxAmount: item.generalTaxAmount,
            generalTaxPercentage: item.generalTaxPercentage,
            generalTaxType: item.generalTaxType,
            totalAmountAfterDiscount: item.totalAmountAfterDiscount,
            discountAmount: item.discountAmount,
            subtotalAmount: item.subtotalAmount,
            specialTaxAmount: item.specialTaxAmount,
            unitPrice: item.unitPrice,
            customerPrice: item.customerPrice,
            quantity: item.quantity,
            description: item.productDescription,
            isic4: item.isic4,
          }))
        : [],
      totalAmountExcludingTaxes: invoice.totalAmountExcludingTaxes,
      totalDiscountsAmount: invoice.totalDiscountsAmount,
      totalGeneralTaxesAmount: invoice.totalGeneralTaxesAmount,
      totalPayableAmount: invoice.totalPayableAmount,
      notes: invoice.notes,
    }));
};

export const getInvoicePDF = ({ invoiceId, invoiceNumber }) => {
  return axios
    .get(`${API_URL}/sme/invoices/pdf/${invoiceId}/${invoiceNumber}`, {
      responseType: 'blob',
    })
    .then((response) => {
      const file = new Blob([response.data], {
        type: 'application/pdf',
      });
      return file;
    });
};

export const getUserInvoices = () =>
  axios.get(API_URL + '/sme/invoices/original-invoices/').then((invoices) =>
    invoices.map((invoice) => ({
      invoiceNumber: invoice.invoiceNumber,
      invoiceType: invoice.invoiceType,
      invoiceUniqueIdentifier: invoice.invoiceUniqueIdentifier,
      issueDate: invoice.issueDate,
      totalAmount: invoice.totalAmou,
    })),
  );

export const generateOTPForForgetPassword = (data, captchaToken) =>
  axios.post(API_URL + '/users/forget/generate-otp', data, {
    headers: { 'g-recaptcha-response': captchaToken },
  });
export const verifyForgetPasswordOTP = (data) =>
  axios.post(API_URL + '/users/forget/verify-otp', data);
export const getUsername = (data, otp) =>
  axios.post(API_URL + '/users/forget/username', data, {
    headers: { OTP: otp },
  });
export const updatePassword = (data, otp) =>
  axios.post(API_URL + '/users/forget/password', data, {
    headers: { OTP: otp },
  });

export const logout = () => axios.post(API_URL + '/users/auth/logout', {});

export const changePassword = (data) =>
  axios.post(API_URL + '/users/sub-admin/reset-password', data);

////////////////////////////////////
export const getUserLogo = (activityNumber) => {
  return axios.get(
    API_URL + `/users/user/logo/?activityNumber=${activityNumber?.value}`,
    {
      responseType: 'blob',
      headers: {
        activity: activityNumber?.value,
      },
    },
  );
};

export const uploadUserLogo = (data) =>
  axios.post(API_URL + '/users/user/logo', data);

export const getTaxpayerInvoicesReport = (params, activityNumber, lang) => {
  return axios.get(API_URL + '/sme/invoices/export-excel', {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/octet-stream',
      activity: activityNumber,
      lang: lang,
    },
    params: {
      ...params,
    },
  });
};
export const updateUserData = (taxNumber) => {
  return axios.post(API_URL + '/users/auth/update-taxpayer-info', {
    taxNumber,
  });
};
export const isTaxNumberValid = (taxNumber) => {
  return axios.get(API_URL + '/sme/invoices/invtxpchk/' + taxNumber);
};
