import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import NewInvoiceIcon from 'assets/img/new-invoice.svg';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { InvoiceSellerSection } from 'components/InvoiceSellerSection';
import { InvoiceBuyerSection } from 'components/InvoiceBuyerSection';
import { InvoiceNotes } from 'components/InvoiceNotes';

import { FormErrorToast } from './components/FormErrorToast';

import {
  validationSchema,
  getEditInvoiceInitialValues,
} from 'config/invoice/formConfig';

import { InvoiceMainSection } from './components/InvoiceMainSection';
import { InvoiceProductsSection } from './components/InvoiceProductsSection';

import styles from './EditInvoicePage.styles.scss';

const EditInvoicePage = ({
  loading,
  submitLoading,
  user,
  invoiceNumber,
  invoiceType,
  setInvoiceType,
  loadActivityOptions,
  onAddItemClick,
  onRemoveItemClick,
  onCancelClick,
  onSubmitFormClick,
  invoice,
  setInvoice,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.header}>
              <Breadcrumbs
                routeName={t('modifyInvoice_routeName')}
                routeIcon={NewInvoiceIcon}
              />
            </div>
            <div className={styles.inner}>
              <Formik
                validateOnMount
                enableReinitialize
                validationSchema={validationSchema({
                  t,
                  invoice,
                  invoiceType,
                  edit: true,
                })}
                initialValues={getEditInvoiceInitialValues({
                  user,
                  invoiceNumber,
                  originalInvoiceNumber: invoice?.invoiceNumber,
                })}
                onSubmit={onSubmitFormClick}
              >
                {() => (
                  <>
                    <FormErrorToast />
                    <Form
                      className={styles.form}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    >
                      <div className={styles.formInner}>
                        <InvoiceMainSection
                          setInvoice={setInvoice}
                          setInvoiceType={setInvoiceType}
                        />
                        <InvoiceSellerSection
                          invoiceType={invoiceType}
                          user={user}
                          loadActivityOptions={loadActivityOptions}
                        />
                        <InvoiceBuyerSection isEdit={true} />
                        <InvoiceProductsSection
                          isEdit={true}
                          invoiceType={invoiceType}
                          invoice={invoice}
                          onAddItemClick={onAddItemClick}
                          onRemoveItemClick={onRemoveItemClick}
                        />
                      </div>

                      <InvoiceNotes />
                      <div className={styles.actions}>
                        <Button
                          className={styles.action}
                          variant="outlined"
                          onClick={onCancelClick}
                        >
                          {t('newInvoice_exit')}
                        </Button>
                        <Button
                          className={styles.action}
                          type="submit"
                          loading={submitLoading}
                        >
                          {t('newInvoice_generate')}
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default React.memo(EditInvoicePage);
