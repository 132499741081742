import React from 'react';

import { getProductWithTotals } from 'config/invoice/helpers';

import EditInvoiceProductModal from './EditInvoiceProductModal';

const EditInvoiceProductModalContainer = ({
  itemIndex,
  invoiceType,
  parentSetFieldValue,
  parentValues,
  onClose,
  ...restProps
}) => {
  const handleFormSubmit = (currentItem) => {
    const nextItems = parentValues.items.map((item, index) => {
      if (index === itemIndex) {
        currentItem.quantity = Number(currentItem.quantity).toFixed(9);
        currentItem.specialTaxAmount = Number(
          currentItem.specialTaxAmount,
        ).toFixed(9);
        currentItem.subtotalAmount = Number(currentItem.subtotalAmount).toFixed(
          9,
        );
        currentItem.unitPrice = Number(currentItem.unitPrice).toFixed(9);
        currentItem.customerPrice = Number(currentItem.customerPrice).toFixed(
          9,
        );

        return getProductWithTotals({
          item: currentItem,
          invoiceType,
          isCustomerPriceEnabled: parentValues.seller.isCustomerPriceEnabled,
        });
      }

      return item;
    });

    parentSetFieldValue('items', nextItems);

    onClose();
  };

  return (
    <EditInvoiceProductModal
      {...restProps}
      invoiceType={invoiceType}
      onClose={onClose}
      onFormSubmit={handleFormSubmit}
    />
  );
};

export default React.memo(EditInvoiceProductModalContainer);
