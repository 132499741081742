import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import * as api from 'api/methods';

import { INVOICE_TYPES_ENUM } from 'constants/common';

import {
  defaultItemData,
  PHONE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
  NAME_REGEX,
} from './constants';

import {
  additionalBuyerIdValidation,
  discountMaxValidation,
} from './validations';

import { calculateTotalAmountAfterTaxes } from './helpers';
import { Currencies, CurrenciesFullName, Invoice_Kind } from './config';

export const validationSchema = ({ t, invoice, invoiceType, edit }) => {
  const defaultShape = {
    issueDate: Yup.string()
      .required(t('create_invoice_validation_issue_date_required'))
      .test(
        'issueDateValidation',
        t('create_invoice_validation_issue_date_more_original_issue_date'),
        (value, context) => {
          const originalInvoiceNumber = context.parent.originalInvoiceNumber;
          if (!originalInvoiceNumber || !value) {
            return true;
          }
          // const Invoice = api.getUserInvoice('ein19475');

          const originalInvoice = invoice;

          const originaInvoiceIssueDate = parse(
            originalInvoice?.issueDate,
            'dd-MM-yyyy',
            new Date(),
          );

          return (
            isAfter(
              parse(value, 'dd/MM/yyyy', new Date()),
              originaInvoiceIssueDate,
            ) ||
            format(parse(value, 'dd/MM/yyyy', new Date()), 'dd-MM-yyyy') ===
              originalInvoice?.issueDate
          );
        },
      )
      .test(
        'issueDateValidation2',
        t('create_invoice_validation_issue_date_max'),
        (value) => {
          if (edit) {
            return true;
          }

          return !isAfter(parse(value, 'dd/MM/yyyy', new Date()), new Date());
        },
      ),
    invoiceNumber: Yup.string().required(
      t('create_invoice_validation_invoice_number_required'),
    ),
    tempItem: Yup.object().shape({
      generalTaxPercentage: Yup.object(),
      discountAmount: Yup.number()
        .typeError(t('create_invoice_validation_discount_allownece'))
        .moreThan(-1, t('create_invoice_validation_discount_allownece'))
        .when(
          ['unitPrice', 'quantity'],
          (unitPrice, quantity, discountAmountSchema) =>
            discountMaxValidation({
              t,
              unitPrice,
              quantity,
              discountAmountSchema,
            }),
        )
        .test(
          'maxDecimalDigits',
          t('create_invoice_validation_discount_allownece'),
          (value) => {
            if (typeof value === 'undefined') {
              return true;
            }
            if (isNaN(value)) {
              return false;
            }
            const [, decimalPart] = value.toString().split('.');
            if (!decimalPart) {
              return true;
            }

            return decimalPart.length <= 9;
          },
        ),
      unitPrice: Yup.number()
        .typeError(t('create_invoice_validation_unit_price_allownece'))
        .moreThan(0, t('create_invoice_validation_unit_price_allownece'))
        .test(
          'maxDecimalDigits',
          t('create_invoice_validation_unit_price_allownece'),
          (value) => {
            if (typeof value === 'undefined') {
              return true;
            }
            if (isNaN(value)) {
              return false;
            }
            const [, decimalPart] = value.toString().split('.');
            if (!decimalPart) {
              return true;
            }

            return decimalPart.length <= 9;
          },
        ),
      quantity: Yup.number()
        .typeError(t('create_invoice_validation_quantity_allownece'))
        .positive(t('create_invoice_validation_quantity_allownece'))
        .when(['type'], (type, quantitySchema) => {
          return type?.value === 'PRODUCT'
            ? quantitySchema.moreThan(
                -1,
                t('create_invoice_validation_quantity_allownece'),
              )
            : quantitySchema;
        })
        .test(
          'maxDecimalDigits',
          t('create_invoice_validation_quantity_allownece'),
          (value) => {
            if (typeof value === 'undefined') {
              return true;
            }
            if (isNaN(value)) {
              return false;
            }
            const [, decimalPart] = value.toString().split('.');
            if (!decimalPart) {
              return true;
            }

            return decimalPart.length <= 9;
          },
        ),
      description: Yup.string()
        .ensure()
        .matches(
          /^(?! )/,
          t('create_invoice_validation_discription_whiteSpace'),
        ),
      type: Yup.object(),
    }),
  };

  if (edit) {
    defaultShape.reasonOfNote = Yup.string().required(
      t('create_invoice_validation_reason_of_note_required'),
    );
  }

  const buyerShapeRequired = Yup.object().shape({
    name: Yup.string()
      .required(t('create_invoice_validation_buyer_name_required'))
      .matches(NAME_REGEX, t('create_invoice_validation_buyer_name_allownece')),
  });

  if (edit) {
    if (
      invoice?.invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ||
      invoice?.invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_GENERAL_TAX ||
      invoice?.invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX
    ) {
      return Yup.object().shape({
        ...defaultShape,
        buyer: buyerShapeRequired,
      });
    } else {
      const buyerShape = Yup.object().shape({
        name: Yup.string()
          .ensure()
          .test(
            'usernameValidation',
            t('create_invoice_validation_buyer_name_allownece'),
            (value) => {
              if (!value) {
                return true;
              }

              const regex = new window.RegExp(NAME_REGEX);

              return regex.test(value);
            },
          ),
        postalCode: Yup.string()
          .ensure()
          .nullable()
          .matches(
            POSTAL_CODE_REGEX,
            t('create_invoice_validation_postal_code_allownece'),
          ),
        phoneNumber: Yup.string()
          .ensure()
          .test(
            'phoneNumberValidation',
            t('create_invoice_validation_mobile_number_allownece'),
            (value) => {
              if (!value) {
                return true;
              }

              const regex = new window.RegExp(PHONE_NUMBER_REGEX);

              return regex.test(value);
            },
          ),
        province: edit ? Yup.string() : Yup.object(),
        additionalBuyerIdType: edit ? Yup.string() : Yup.object(),

        additionalBuyerId: Yup.string()
          .ensure()
          .when(
            ['additionalBuyerIdType'],
            (additionalBuyerIdType, additionalBuyerIdSchema) =>
              additionalBuyerIdValidation({
                t,
                additionalBuyerIdType,
                additionalBuyerIdSchema,
              }),
          ),
      });

      return Yup.object().shape({
        ...defaultShape,
        buyer: Yup.object().when(['items'], {
          is: (items) => {
            const invoiceTotal = items
              ? calculateTotalAmountAfterTaxes(items)
              : 0;

            return invoiceTotal >= 10000 && !edit;
          },
          then: () => buyerShapeRequired,
          otherwise: () => buyerShape,
        }),
      });
    }
  } else {
    if (
      invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_INCOME ||
      invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_GENERAL_TAX ||
      invoiceType === INVOICE_TYPES_ENUM.RECEIVABLE_SPECIAL_TAX
    ) {
      return Yup.object().shape({
        ...defaultShape,
        buyer: buyerShapeRequired,
      });
    } else {
      const buyerShape = Yup.object().shape({
        name: Yup.string()
          .ensure()
          .test(
            'usernameValidation',
            t('create_invoice_validation_buyer_name_allownece'),
            (value) => {
              if (!value) {
                return true;
              }

              const regex = new window.RegExp(NAME_REGEX);

              return regex.test(value);
            },
          ),
        postalCode: Yup.string()
          .ensure()
          .nullable()
          .matches(
            POSTAL_CODE_REGEX,
            t('create_invoice_validation_postal_code_allownece'),
          ),
        phoneNumber: Yup.string()
          .ensure()
          .test(
            'phoneNumberValidation',
            t('create_invoice_validation_mobile_number_allownece'),
            (value) => {
              if (!value) {
                return true;
              }

              const regex = new window.RegExp(PHONE_NUMBER_REGEX);

              return regex.test(value);
            },
          ),
        province: edit ? Yup.string() : Yup.object(),
        additionalBuyerIdType: edit ? Yup.string() : Yup.object(),

        additionalBuyerId: Yup.string()
          .ensure()
          .when(
            ['additionalBuyerIdType'],
            (additionalBuyerIdType, additionalBuyerIdSchema) =>
              additionalBuyerIdValidation({
                t,
                additionalBuyerIdType,
                additionalBuyerIdSchema,
              }),
          ),
      });

      return Yup.object().shape({
        ...defaultShape,
        buyer: Yup.object().when(['items'], {
          is: (items) => {
            const invoiceTotal = items
              ? calculateTotalAmountAfterTaxes(items)
              : 0;

            return invoiceTotal >= 10000 && !edit;
          },
          then: () => buyerShapeRequired,
          otherwise: () => buyerShape,
        }),
      });
    }
  }
};

export const getEditInvoiceInitialValues = ({
  user,
  invoiceNumber,
  originalInvoiceNumber = '',
}) => {
  return {
    issueDate: format(new Date(), 'dd/MM/yyyy'),
    invoiceNumber,
    invoiceTypeCodeTemp: '',
    buyerInvoiceNumber: '',
    originalInvoiceNumber,
    originalInvoiceTotal: '',
    originalInvoiceDate: '',
    reasonOfNote: '',
    noteType: 'دائن',
    seller: {
      taxNumber: user.taxNumber,
      activityNumber: user.activityNumber,
      postalCode: user.postalCode,
      phoneNumber: user.phoneNumber,
      // name: user.activitiesList[0]?.description || user.name,
      country: user.country?.name,
      isCustomerPriceEnabled: false,
    },
    buyer: {
      name: '',
      postalCode: '',
      phoneNumber: '',
      province: '',
      additionalBuyerIdType: '',
      additionalBuyerId: '',
      isTaxNumberValid: false,
    },
    tempItem: {
      id: uuid(),
      ...defaultItemData,
    },
    items: [],
    notes: '',
  };
};

export const getNewInvoiceInitialValues = ({ user, invoiceNumber, t }) => ({
  issueDate: format(new Date(), 'dd/MM/yyyy'),
  invoiceNumber,
  buyerInvoiceNumber: '',
  currencyType: Currencies(t).map((item) => ({
    value: item.value,
    // label: `${item.label}`,
    label: `${CurrenciesFullName(t)[item.value]} (${item.label})`,
  }))[0],
  invoiceKind: Invoice_Kind(t)[0],
  seller: {
    taxNumber: user.taxNumber,
    activityNumber: user.activityNumber,
    postalCode: user.postalCode,
    phoneNumber: user.phoneNumber,
    name: user.activitiesList[0]?.description || user.name,
    country: user.country?.name,
    isCustomerPriceEnabled:
      user.activitiesList[0] &&
      user.activitiesList[0].invoiceType === 2 &&
      user.activitiesList[0].customerPriceEnabled,
  },
  buyer: {
    name: '',
    postalCode: '',
    phoneNumber: '',
    province: '',
    additionalBuyerIdType: '',
    additionalBuyerId: '',
    isTaxNumberValid: false,
  },
  tempItem: {
    id: uuid(),
    ...defaultItemData(),
  },
  items: [],
  notes: '',
});

export default {
  getNewInvoiceInitialValues,
  getEditInvoiceInitialValues,
  validationSchema,
};
