import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'helpers/showToast';
import { useUser } from 'context/user';

import * as api from 'api/methods';

import {
  PROVINCE_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  TAX_RATE_OPTIONS,
  TYPE_OPTIONS,
} from 'config/invoice/config';

import {
  getTaxRateValueByType,
  getTaxRateValueByTypeForTable,
} from 'config/invoice/helpers';

import InvoiceMainSection from './InvoiceMainSection';
import { Currencies, Invoice_Kind } from '../../../../config/invoice/config';

const InvoiceMainSectionContainer = ({ ...restProps }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleOriginalInvoiceNumberChange = async (
    value,
    setFieldValue,
    setErrorMessage,
  ) => {
    try {
      const userInvoice = await api.getUserInvoice(value, user.activityNumber);
      restProps.setInvoice(userInvoice);
      api
        .getOriginalInvoiceById({
          invoiceId: userInvoice.invoiceUniqueIdentifier,
          invoiceNumber: userInvoice.invoiceNumber,
          activity: user.activityNumber,
        })
        .then((invoice) => {
          setFieldValue('invoiceTypeCodeTemp', invoice.invoiceType);
          setFieldValue('originalInvoiceNumber', value);
          setFieldValue('originalInvoiceTotal', userInvoice.totalAmount);

          setFieldValue(
            'currencyType',
            Currencies(t).find((item) => {
              return item.value === invoice.currencyType;
            }),
          );
          setFieldValue(
            'invoiceKind',
            Invoice_Kind(t).find((item) => {
              return item.value === invoice.invoiceKind;
            }),
          );

          const provinceOption = PROVINCE_OPTIONS().find(
            (option) => option.value === invoice?.buyer?.provinceCode,
          );
          const buyerIdTypeOption = DOCUMENT_TYPE_OPTIONS(t).find(
            (option) => option.value === invoice?.buyer?.buyerIdTypeCode,
          );

          setFieldValue('originalInvoiceDate', invoice.issueDate);
          setFieldValue(
            'originalInvoiceUUID',
            userInvoice.invoiceUniqueIdentifier,
          );
          setFieldValue('seller.name', invoice?.activityDTO?.description);
          setFieldValue(
            'seller.isCustomerPriceEnabled',
            user.activitiesList[0] &&
              user.activitiesList[0].invoiceType === 2 &&
              user.activitiesList[0].customerPriceEnabled,
          );

          setFieldValue('buyer.name', invoice?.buyer?.name);
          setFieldValue('buyer.postalCode', invoice?.buyer?.postalCode);
          setFieldValue('buyer.phoneNumber', invoice?.buyer?.phoneNumber);
          setFieldValue('buyer.province', provinceOption?.label || '');
          setFieldValue(
            'buyer.additionalBuyerIdType',
            buyerIdTypeOption?.label || '',
          );
          setFieldValue('buyer.additionalBuyerId', invoice?.buyer?.buyerId);

          setFieldValue(
            'items',
            invoice.items.map((item) => {
              const generalTaxPercentageOption = TAX_RATE_OPTIONS(t).find(
                (option) => {
                  // return (
                  //   getTaxRateValueByTypeForTable(option.value) ===
                  //   item.generalTaxPercentage
                  // );
                  return option.value === item.generalTaxType;
                },
              );

              const typeOption = TYPE_OPTIONS(t).find(
                (option) => option.value === item.type,
              );

              return {
                creating: false,
                totalAmountAfterTaxes: item.totalAmountAfterTaxes,
                generalTaxAmount: item.generalTaxAmount,
                generalTaxPercentage: generalTaxPercentageOption,
                totalAmountAfterDiscount: item.totalAmountAfterDiscount,
                discountAmount: item.discountAmount,
                subtotalAmount: item.subtotalAmount,
                specialTaxAmount: item.specialTaxAmount,
                unitPrice: item.unitPrice,
                customerPrice: item.customerPrice,
                quantity: item.quantity,
                description: item.description,
                type: typeOption,
                id: item.id,
              };
            }),
          );

          setFieldValue('notes', invoice.notes);
        })
        .catch((error) => {
          console.error('Error fetching original invoice:', error);
          setErrorMessage('Error fetching original invoice. Please try again.');
        });
    } catch (error) {
      if (error) {
        showToast({
          data: {
            variant: 'error',
            text: 'Please Enter Correct Invoice Number',
          },
        });
      }
    }
  };

  // const handleOriginalInvoiceNumberChange = async (value, setFieldValue) => {
  //   const userInvoice = await api.getUserInvoice(value);
  //   restProps.setInvoice(userInvoice);
  //   api
  //     .getOriginalInvoiceById({
  //       invoiceId: userInvoice.invoiceUniqueIdentifier,
  //       invoiceNumber: userInvoice.invoiceNumber,
  //     })
  //     .then((invoice) => {
  //       setFieldValue('originalInvoiceTotal', userInvoice.totalAmount);

  //       const provinceOption = PROVINCE_OPTIONS().find(
  //         (option) => option.value === invoice?.buyer?.provinceCode,
  //       );
  //       const buyerIdTypeOption = DOCUMENT_TYPE_OPTIONS(t).find(
  //         (option) => option.value === invoice?.buyer?.buyerIdTypeCode,
  //       );

  //       setFieldValue('originalInvoiceDate', invoice.issueDate);
  //       setFieldValue(
  //         'originalInvoiceUUID',
  //         userInvoice.invoiceUniqueIdentifier,
  //       );
  //       setFieldValue('buyer.name', invoice?.buyer?.name);
  //       setFieldValue('buyer.postalCode', invoice?.buyer?.postalCode);
  //       setFieldValue('buyer.phoneNumber', invoice?.buyer?.phoneNumber);
  //       setFieldValue('buyer.province', provinceOption?.label || '');
  //       setFieldValue(
  //         'buyer.additionalBuyerIdType',
  //         buyerIdTypeOption?.label || '',
  //       );
  //       setFieldValue('buyer.additionalBuyerId', invoice?.buyer?.buyerId);

  //       setFieldValue(
  //         'items',
  //         invoice.items.map((item) => {
  //           const generalTaxPercentageOption = TAX_RATE_OPTIONS(t).find(
  //             (option) =>
  //               getTaxRateValueByType(option.value) ===
  //               item.generalTaxPercentage,
  //           );
  //           const typeOption = TYPE_OPTIONS(t).find(
  //             (option) => option.value === item.type,
  //           );

  //           return {
  //             creating: false,
  //             totalAmountAfterTaxes: item.totalAmountAfterTaxes,
  //             generalTaxAmount: item.generalTaxAmount,
  //             generalTaxPercentage: generalTaxPercentageOption,
  //             totalAmountAfterDiscount: item.totalAmountAfterDiscount,
  //             discountAmount: item.discountAmount,
  //             subtotalAmount: item.subtotalAmount,
  //             specialTaxAmount: item.specialTaxAmount,
  //             unitPrice: item.unitPrice,
  //             quantity: item.quantity,
  //             description: item.description,
  //             type: typeOption,
  //             id: item.id,
  //           };
  //         }),
  //       );

  //       setFieldValue('notes', invoice.notes);
  //     })
  //     .catch(() => {
  //       // TODO: catch errors here if needed
  //     });
  // };

  return (
    <InvoiceMainSection
      {...restProps}
      onOriginalInvoiceNumberChange={handleOriginalInvoiceNumberChange}
      error={errorMessage}
    />
  );
};

export default React.memo(InvoiceMainSectionContainer);
