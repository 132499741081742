import React from 'react';

import { useModal } from 'hooks/useModal';

import { EditInvoiceProductModal } from '../EditInvoiceProductModal';

import InvoiceProductsSection from './InvoiceProductsSection';
import { DeleteInvoiceProductModal } from '../DeleteInvoiceProductModal';

const InvoiceProductsSectionContainer = ({
  invoiceType,
  isEdit,
  invoice,
  ...restProps
}) => {
  const [
    editInvoiceProductModalOpen,
    openEditInvoiceProductModal,
    closeEditInvoiceProductModal,
    editInvoiceProductModalData,
  ] = useModal({ defaultOpen: false });
  const [
    deleteInvoiceProductModalOpen,
    openDeleteInvoiceProductModal,
    closeDeleteInvoiceProductModal,
    deleteInvoiceProductModalData,
  ] = useModal({ defaultOpen: false });

  const handleEditItemClick = (
    { item, itemIndex },
    { values, setFieldValue },
  ) => {
    openEditInvoiceProductModal({
      item,
      itemIndex,
      invoiceType,
      parentValues: values,
      parentSetFieldValue: setFieldValue,
    });
  };

  return (
    <>
      <InvoiceProductsSection
        isEdit={isEdit}
        {...restProps}
        invoiceType={invoiceType}
        onEditItemClick={handleEditItemClick}
        openDeleteInvoiceProductModal={openDeleteInvoiceProductModal}
      />
      <EditInvoiceProductModal
        isEdit={isEdit}
        {...editInvoiceProductModalData}
        invoice={invoice}
        open={editInvoiceProductModalOpen}
        onClose={closeEditInvoiceProductModal}
      />
      <DeleteInvoiceProductModal
        isEdit={isEdit}
        {...deleteInvoiceProductModalData}
        open={deleteInvoiceProductModalOpen}
        onClose={closeDeleteInvoiceProductModal}
        onRemoveItemClick={restProps.onRemoveItemClick}
      />
    </>
  );
};

export default React.memo(InvoiceProductsSectionContainer);
