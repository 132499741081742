import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import NewInvoiceIcon from 'assets/img/new-invoice.svg';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { MainLayout } from 'components/MainLayout';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { InvoiceSellerSection } from 'components/InvoiceSellerSection';
import { InvoiceBuyerSection } from 'components/InvoiceBuyerSection';
import { InvoiceNotes } from 'components/InvoiceNotes';

import {
  validationSchema,
  getNewInvoiceInitialValues,
} from 'config/invoice/formConfig';

import { calculateTotalAmountAfterTaxes } from 'config/invoice/helpers';

import { FormErrorToast } from './components/FormErrorToast';
import { InvoiceMainSection } from './components/InvoiceMainSection';
import { InvoiceProductsSection } from './components/InvoiceProductsSection';

import styles from './NewInvoicePage.styles.scss';

const NewInvoicePage = ({
  loading,
  submitLoading,
  isBuyerFieldsRequired,
  user,
  invoiceNumber,
  invoiceType,
  loadActivityOptions,
  onAddItemClick,
  onRemoveItemClick,
  onCancelClick,
  onSubmitFormClick,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout loading={loading}>
      <div className={styles.page}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.header}>
              <Breadcrumbs
                routeName={t('newInvoice_routeName')}
                routeIcon={NewInvoiceIcon}
              />
            </div>
            <div className={styles.inner}>
              <Formik
                validateOnMount
                enableReinitialize
                validationSchema={validationSchema({ t, invoiceType })}
                initialValues={getNewInvoiceInitialValues({
                  user,
                  invoiceNumber,
                  t,
                })}
                onSubmit={onSubmitFormClick}
              >
                {({ values }) => (
                  <>
                    <FormErrorToast />
                    <Form
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                      className={styles.form}
                    >
                      <div className={styles.formInner}>
                        <InvoiceMainSection />
                        <InvoiceSellerSection
                          user={user}
                          invoiceType={invoiceType}
                          loadActivityOptions={loadActivityOptions}
                        />
                        <InvoiceBuyerSection
                          isBuyerFieldsRequired={
                            isBuyerFieldsRequired ||
                            calculateTotalAmountAfterTaxes(
                              values.items,
                              values.invoiceKind?.value,
                            ) >= 10000
                          }
                        />
                        <InvoiceProductsSection
                          user={user}
                          invoiceType={invoiceType}
                          onAddItemClick={onAddItemClick}
                          onRemoveItemClick={onRemoveItemClick}
                        />
                      </div>

                      <InvoiceNotes />

                      <div className={styles.actions}>
                        <Button
                          className={styles.action}
                          variant="outlined"
                          onClick={onCancelClick}
                        >
                          {t('newInvoice_exit')}
                        </Button>
                        <Button
                          className={styles.action}
                          type="submit"
                          loading={submitLoading}
                        >
                          {t('newInvoice_generate')}
                        </Button>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default React.memo(NewInvoicePage);
