import * as Yup from 'yup';

import { discountMaxValidation } from 'config/invoice/validations';

export const validationSchema = (t) =>
  Yup.object().shape({
    generalTaxPercentage: Yup.object(),
    discountAmount: Yup.number()
      .min(0, t('create_invoice_validation_discount_allownece'))
      .typeError(t('create_invoice_validation_discount_allownece'))
      .when(
        ['unitPrice', 'quantity'],
        (unitPrice, quantity, discountAmountSchema) =>
          discountMaxValidation({
            t,
            unitPrice,
            quantity,
            discountAmountSchema,
          }),
      ),
    unitPrice: Yup.number()
      .required(t('create_invoice_validation_unit_price_required'))
      .typeError(t('create_invoice_validation_unit_price_allownece'))
      .moreThan(0, t('create_invoice_validation_unit_price_allownece')),
    quantity: Yup.number()
      .required(t('create_invoice_validation_quantity_allownece'))
      .typeError(t('create_invoice_validation_quantity_allownece'))
      .positive(t('create_invoice_validation_quantity_allownece'))
      .when(['type'], (type, quantitySchema) => {
        return type?.value === 'PRODUCT'
          ? quantitySchema.moreThan(
              0,
              t('create_invoice_validation_quantity_allownece'),
            )
          : quantitySchema;
      }),
    description: Yup.string()
      .required(t('create_invoice_validation_description_required'))
      .ensure()
      .matches(/^(?! )/, t('create_invoice_validation_discription_whiteSpace')),
    type: Yup.object(),
  });

export const initialValues = {
  generalTaxAmount: '',
  generalTaxPercentage: { value: 16, label: '16%' },
  discountAmount: '',
  subtotalAmount: '',
  specialTaxAmount: 0,
  unitPrice: '',
  customerPrice: '',
  quantity: '',
  description: '',
  type: { value: 'PRODUCT', label: 'سلعة/خدمة' },
};

export default {
  initialValues,
  validationSchema,
};
