import PdfAr from '../../../assets/pdf/Arabic_user_manual.pdf';
import PdfEn from '../../../assets/pdf/User_Manual.pdf';

export const footerLinks = {
  instagram: 'https://www.instagram.com/jordantax2/',
  facebook: 'https://www.facebook.com/ISTDJordanOfficial/',
  twitter: 'https://twitter.com/istdjordan',
  youTube:
    'https://www.youtube.com/channel/UCeWd1t_anSRIkMEag8oMa0A?feature=c4-feed-u',
  agile: 'https://www.b-tek.dev/',
  privacy: {
    ar: 'https://www.istd.gov.jo/AR/Pages/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9_%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9?View=2101',
    en: 'https://www.istd.gov.jo/EN/Pages/Privacy_Policy',
  },
  terms: {
    ar: 'https://www.istd.gov.jo/AR/Pages/%D8%B4%D8%B1%D9%88%D8%B7_%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85',
    en: 'https://www.istd.gov.jo/EN/Pages/Terms_of_Use',
  },
  disclaimer: {
    ar: 'https://www.istd.gov.jo/AR/Pages/%D8%A5%D8%AE%D9%84%D8%A7%D8%A1_%D8%A7%D9%84%D9%85%D8%B3%D8%A4%D9%88%D9%84%D9%8A%D8%A9',
    en: 'https://www.istd.gov.jo/EN/Pages/Disclaimer',
  },
  copyRights: {
    ar: 'https://www.istd.gov.jo/AR/Pages/%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%86%D8%B3%D8%AE_%D9%88_%D8%A7%D9%84%D9%86%D8%B4%D8%B1?View=2111',
    en: 'https://www.istd.gov.jo/EN/Pages/Copyrights',
  },
  userManual: {
    ar: PdfAr,
    en: PdfAr,
  },
};
